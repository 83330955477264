import React, { useState } from 'react';
import Button from '../../molecules/Button/Button';
import PortableText from '../PortableText/PortableText';

function Review({review, primary}) {

  const [isOpen, setIsOpen] = useState(false);

  return(
    <div className="flex flex-nowrap mb-4">
      <div className={`w-full bg-white px-4 md:px-16 py-8 ${primary ? "xl:w-3/5" : "order-last"}`}>
        <div className="text-3xl font-lato font-bold text-brand-red mb-2">{review.reviewerName}</div>
        {review.reviewerTitle ? <div className="font-lato font-light mb-8">{review.reviewerTitle}</div> : <></>}
        
        <div className="font-lato mb-8">
          <PortableText blocks={isOpen ? review._rawFullReview : review._rawSummary} />
        </div>
        
        {primary ?
          <Button href="/reviews" textOnDarkBg={false} internal="true">Read All</Button> 
          :
          review._rawFullReview ?
            <button onClick={() => setIsOpen(!isOpen)} className="mb-4 mr-4 inline-flex items-center text-xs font-lato font-bold leading-3 px-12 py-4 border-2 border-black uppercase bg-transparent transition transition-duration-500 hover:bg-brand-red hover:border-brand-red hover:text-white">{isOpen ? "Read Less" : "Read More"}</button> 
            :
            <></>
        }
      </div>
      {primary ?
        <div className="hidden xl:inline-flex w-2/5 bg-brand-red flex justify-center items-center px-8 py-16">
          <p className="text-4xl font-lato font-bold text-white">{review.callOutText}</p> 
        </div>
        :
        <></>
      }
    </div>
  );
}

export default Review;