import React from 'react';
import BasePortableText from '@sanity/block-content-to-react';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';
import Img from 'gatsby-image';
import clientConfig from '../../../../client-config';
import Link from '../../atoms/Link/Link';
// This is for standard HTML elements within the standard portable text "block"

const BlockRenderer = (props) => {
  switch (props.node.style) {
    case 'h1':
      return <h1 className="font-lato text-lg h1 mt-8">{props.children}</h1>;

    case 'h2':
      return <h2 className="font-lato text-lg h2 mt-4 font-bold">{props.children}</h2>;

    case 'h3':
      return <h3 className="font-lato text-lg h3 mt-4">{props.children}</h3>;

    case 'h4':
      return <h4 className="font-lato text-lg h4 mt-4">{props.children}</h4>;

    case 'blockquote':
      return <blockquote className="">{props.children}</blockquote>;

    case 'normal':
      return <p className="font-lato text-lg mb-4">{props.children}</p>;

    default:
      return BasePortableText.defaultSerializers.types.block(props);
  }
};

// Links
const link = (props) => (
  <Link to={props.mark.href} className="text-blue-500 underline">
    {props.children}
  </Link>
);

// Lists
const list = (props) => {
  const { type } = props;
  const bullet = type === 'bullet';
  if (bullet) {
    return <ul className="list-disc list-outside ml-8">{props.children}</ul>;
  }
  return <ol className="list-decimal list-outside ml-8">{props.children}</ol>;
};

// List Items - this is actually default but we may need to target the items in future
const listItem = (props) => (<li className="mb-3">{props.children}</li>);

// Images
const mainImage = (props) => {
  const alignMap = {
    notapplicable: '',
    none: '',
    left: 'float-left w-1/2 mr-5',
    right: 'float-right w-1/2 ml-5',
    leftsm: 'float-left w-1/4 mr-8',
    rightsm: 'float-right w-1/4 ml-8',
  };

  const alignClasses = alignMap[props.node.alignment];

  const fluidProps = getFluidGatsbyImage(
    props.node.image.asset._id,
    { maxWidth: 1200 },
    clientConfig.sanity,
  );

  // This contains a recursive PortableText mmm.......
  return (
    <>
      <figure className={`${alignClasses}`}>
        <Img fluid={fluidProps} alt={props.node.alt} />
        <caption className="text-sm w-full inline text-left">{props.node.caption}</caption>
      </figure>
    </>
  );
};

const serializer = {
  // Get ultimate control of bulleted lists
  list,
  listItem,
  marks: { link },
  types: {
    block: BlockRenderer,
    mainImage,
  },
  // block: props => <pre>{JSON.stringify(props,null,2)}</pre>
};

const PortableText = ({ blocks }) => (
  <BasePortableText blocks={blocks} serializers={serializer} {...clientConfig.sanity} />
);

export default PortableText;
